<template>
    <div class="h5-container">
        <div class="page-title">{{$t('Module.Assets')}}</div>
        <div class="balance">
        	<div class="label">{{$t('Assets.TotalBalance')}}</div>
        	<div class="value animate__animated animate__bounceIn">
        		{{assetSum}}USDT
        		<br />
        		≈{{assetSum}}$
        	</div>
        </div>
        <div class="action-list">
        	<div v-for="(item,index) in actionList" :key="index" class="action-item" @click="to(item.url)">
        		<img class="icon" :src="item.icon" />
        		<div class="name">{{item.name}}</div>
        	</div>
        </div>
        <div class="asset-box">
            <el-table :data="assetList" style="width: 100%;">
                <el-table-column prop="type" :label="$t('Assets.Currency')">
                </el-table-column>
                <el-table-column prop="num" :label="$t('Assets.AvailableBalance')">
                </el-table-column>
                <el-table-column prop="frozen" :label="$t('Assets.Frozen')">
                </el-table-column>
                <el-table-column :label="$t('Assets.Operate')">
                    <template slot-scope="scope">
                        <div class="operate-btns">
                            <div class="operate-btn" @click="toTrade('/h5/assets/deposit',scope.row.type)">{{$t('Assets.Deposit')}}</div>
                            <div class="operate-btn" @click="toTrade('/h5/assets/withdraw',scope.row.type)">{{$t('Assets.Withdraw')}}</div>
                            <div class="operate-btn" @click="toTrade('/h5/assets/transfer',scope.row.type)">{{$t('Assets.Transfer')}}</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="bill-box">
            <div class="filter">
                <div class="label">{{$t('Assets.Type')}}</div>
                <el-select v-model="type" placeholder="" @change="change">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <el-table :data="recordList" style="width: 100%;">
                <el-table-column :label="$t('Assets.Time')">
                    <template slot-scope="scope">
                        <div class="first-column">
                            <span v-if="scope.row.action && scope.row.action.ai == 1" class="top">AI</span>
                            <span class="bottom">{{scope.row.created_at}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('Assets.Type')">
                    <template slot-scope="scope">
                        <span>{{curType(scope.row.type)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="user_credit_type" :label="$t('Assets.Currency')">
                </el-table-column>
                <el-table-column :label="$t('Assets.Quantity')">
                    <template slot-scope="scope">
                        <span>{{parseFloat(scope.row.num)}}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="fee" label="手续费">
                </el-table-column>
                <el-table-column prop="balance" label="账户余额">
                </el-table-column>
                <el-table-column prop="remark" label="备注">
                </el-table-column> -->
            </el-table>
            <div v-if="recordList.length > 0" class="pagination-box">
                <el-pagination small @current-change="handleCurrentChange"
                    :current-page="currentPage" :pager-count="5"
                    :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                    :page-size="pageSize" layout="prev, pager, next"
                    :total="total" background>
                </el-pagination>
            </div>
        </div>
        <tabbar :activeId="4"></tabbar>
    </div>
</template>

<script>
    export default {
        components: {
            tabbar: () => import('@/components/tabbar.vue')
        },
        data() {
            return {
                actionList: [
                    {
                        name: this.$t('Assets.Deposit'),
                        icon: require('@/assets/images/h5/assets/tab1.png'),
                        url: '/h5/assets/deposit'
                    },
                    {
                        name: this.$t('Assets.Withdraw'),
                        icon: require('@/assets/images/h5/assets/tab2.png'),
                        url: '/h5/assets/withdraw'
                    },
                    {
                        name: this.$t('Assets.Transfer'),
                        icon: require('@/assets/images/h5/assets/tab3.png'),
                        url: '/h5/assets/transfer'
                    },
                ],
                isCompleted1: false,
                isCompleted2: false,
                options: [
                    {
                        value: '',
                        label: this.$t('FundingType.All')
                    },
                    {
                        value: 'system',
                        label: this.$t('Assets.Deposit')
                    },
                    {
                        value: 'commission',
                        label: this.$t('FundingType.commission')
                    },
                    {
                        value: 'recharge',
                        label: this.$t('FundingType.recharge')
                    },
                    {
                        value: 'withdraw',
                        label: this.$t('FundingType.withdraw')
                    },
                    {
                        value: 'withdrawFail',
                        label: this.$t('FundingType.withdrawFail')
                    },
                    {
                        value: 'second_order',
                        label: this.$t('FundingType.second_order')
                    },
                    {
                        value: 'secondOrderWin',
                        label: this.$t('FundingType.secondOrderWin')
                    },
                    {
                        value: 'perpetual_order',
                        label: this.$t('FundingType.perpetual_order')
                    },
                    {
                        value: 'perpeTualOrderClo',
                        label: this.$t('FundingType.perpeTualOrderClo')
                    },
                    {
                        value: 'trade',
                        label: this.$t('FundingType.trade')
                    },
                    {
                        value: 'buyFinance',
                        label: this.$t('FundingType.buyFinance')
                    },
                    {
                        value: 'financeProfit',
                        label: this.$t('FundingType.financeProfit')
                    },
                    {
                        value: 'financeMoney',
                        label: this.$t('FundingType.financeMoney')
                    }
                ],
                type: '',
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                credit: [],
                frozen: '0.00000000'
            }
        },
        created() {
            this.refresh()
            this.getRecordList(true)
            this.$store.dispatch('getProduct')
        },
        mounted() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        activated() {
            if (!this.$ws.socket) {
                this.$ws.init()
            }
        },
        computed: {
            connect() {
                return this.$store.state.connect
            },
            
            loading() {
                return !this.isCompleted1 && !this.isCompleted2
            },
            
            productList() {
                return this.$store.state.productList
            },
            
            calCoinValue() {
                return (name,num) => {
                    let cur_coin = this.$store.state.productList.find(item => name == item.name)
                    return cur_coin ? num * parseFloat(cur_coin.price.close) : 0
                }
            },
            
            assetList() {
                let list = []
                list = JSON.parse(JSON.stringify(this.credit.map(item => {
                    if(item.type == 'USDT') {
                        item['frozen'] = this.frozen
                    } else {
                        item['frozen'] = '0.00000000'
                    }
                    
                    return item
                })))
                
                return list
            },
            
            usdtSum() {
                let val = 0
                this.assetList.forEach(item => {
                    if(item.type == 'USDT') {
                        val += parseFloat(item.num) + parseFloat(item.frozen)
                    }
                })
                
                return val
            },
            
            btcSum() {
                let val = 0
                this.assetList.forEach(item => {
                    if(item.type == 'BTC') {
                        val += this.calCoinValue(item.type,parseFloat(item.num))
                    }
                })
                
                return val
            },
            
            ethSum() {
                let val = 0
                this.assetList.forEach(item => {
                    if(item.type == 'ETH') {
                        val += this.calCoinValue(item.type,parseFloat(item.num))
                    }
                })
                
                return val
            },
            
            assetSum() {
                return (this.usdtSum + this.btcSum + this.ethSum).toFixed(8)
            },
            
            curType() {
                return type => {
                    let name = ''
                    this.options.forEach(item => {
                        if(type == item.value) {
                            name = item.label
                        }
                    })
                    
                    return name
                }
            }
        },
        watch: {
            connect(value) {
                let val = JSON.parse(value)
                if(val.status) {
                    this.$ws.sendMsg('{"type":"join","data":"ticker"}')
                }
            }
        },
        methods: {
            to(path) {
                this.$router.push({
                    path
                })
            },
            
            toTrade(path,coin_name) {
                this.$router.push({
                    path,
                    query: {
                        coin_name
                    }
                })
            },
            
            getCredit() {
                this.isCompleted1 = false
                this.$api.credit().then(res => {
                    this.isCompleted1 = true
                    this.credit = res.data
                })
            },
            
            getFrozen() {
                this.isCompleted2 = false
                this.$api.frozen().then(res => {
                    this.isCompleted2 = true
                    this.frozen = res.data
                })
            },
            
            refresh() {
                this.getCredit()
                this.getFrozen()
            },
            
            change(e) {
                // console.log(e)
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                this.$api.credit_logs({
                    type: this.type,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .h5-container {
        box-sizing: border-box;
        padding: 0 1.2rem 7.6rem;
        background: url(~@/assets/images/h5/assets/assets-bg.png) no-repeat;
        background-size: 100% auto;
        background-color: #f6f6f6;
        
        .page-title {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 4.8rem;
            font-size: 1.7rem;
            font-weight: bold;
            color: #FFFFFF;
            white-space: nowrap;
        }
        
        .balance {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 0 1.2rem;
        	margin-bottom: 1.6rem;
        
        	.label {
        		width: 100%;
        		margin-bottom: 1.6rem;
        		font-size: 1.5rem;
        		color: #FFFFFF;
        		word-wrap: break-word;
        	}
        
        	.value {
        		width: 100%;
                line-height: 120%;
        		font-size: 2rem;
        		font-weight: bold;
        		color: #FFFFFF;
        		word-break: break-all;
                animation-delay: 400ms;
        	}
        }
        
        .action-list {
            display: flex;
            justify-content: space-around;
            width: 100%;
            box-sizing: border-box;
            padding: 1.6rem 0;
            border-radius: 6px;
            margin-bottom: 2rem;
            background-color: #FFFFFF;
            box-shadow: 0 6px 6px 2px rgba(0, 0, 0, 0.1);
            
            .action-item {
            	display: flex;
            	flex-direction: column;
            	align-items: center;
            	width: 32%;
            
            	.icon {
            		width: 4.4rem;
            		height: 4.4rem;
            		margin-bottom: 8px;
            	}
                
                .name {
                    max-width: 100%;
                    font-size: 1.4rem;
                    color: #000000;
                    text-align: center;
                    word-wrap: break-word;
                }
            }
        }
        
        /deep/ .el-table th.is-leaf {
            padding: 6px 0;
            background-color: #ECEEF8;
        
            .cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 4px;
                font-size: 12px;
                font-weight: normal;
                color: #6D6F7E;
                text-align: center;
            }
        }
        
        /deep/ .el-table__empty-block {
            min-height: 100px;
        
            .el-table__empty-text {
                line-height: 100px;
                font-size: 13px;
            }
        }
        
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: #F3F5FF;
        }
        
        .asset-box {
            width: 100%;
            margin-bottom: 1.6rem;
            
            /deep/ .el-table td {
                padding: 12px 0;
            
                .cell {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 4px;
                    font-size: 12px;
                    color: #000000;
                    text-align: center;
                }
            }
            
            .operate-btns {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                
                .operate-btn {
                    height: 2.8rem;
                    line-height: 2.8rem;
                    box-sizing: border-box;
                    padding: 0 1rem;
                    border-radius: 5px;
                    font-size: 12px;
                    color: #6D6F7E;
                    text-align: center;
                    white-space: nowrap;
                    background-color: #E2E3ED;
                    cursor: pointer;
                    
                    &:not(:last-child) {
                        margin-bottom: 4px;
                    }
                    
                    &:hover {
                        color: #FFFFFF;
                        background-color: #5466EF;
                    }
                }
            }
        }
        
        .bill-box {
            width: 100%;
            box-sizing: border-box;
            padding: 1.6rem 1.2rem;
            background-color: #FFFFFF;
            
            .filter {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                margin-bottom: 1.2rem;
                
                .label {
                    margin-right: 1.6rem;
                    font-size: 1.2rem;
                    color: #000000;
                    white-space: nowrap;
                }
                
                /deep/ .el-input__inner {
                    height: 3.6rem;
                    line-height: 3.6rem;
                    border: 1px solid #e6e6e6;
                    font-size: 1.2rem;
                    color: #000000;
                }
            }
            
            .first-column {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                
                .top {
                    max-width: 100%;
                    margin-bottom: 4px;
                    color: #5466EF;
                }
                
                .bottom {
                    max-width: 100%;
                }
            }
            
            /deep/ .el-table td {
                padding: 8px 0;
            
                .cell {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 4px;
                    font-size: 12px;
                    color: #000000;
                    text-align: center;
                }
            }
            
            .pagination-box {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                box-sizing: border-box;
                padding: 1.6rem 0 0;
                
                /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                    background-color: #5466EF;
                }
                
                /deep/ .el-pager li:not(.active):hover {
                    color: #5466EF;
                }
            }
        }
    }
</style>